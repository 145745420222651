<template>
  <div style="background-color: #f5f7f9">
    <div
      style="width: 100%; background-color: white; height: calc(100% - 60px)"
    >
      <div style="float: left; width: 30%; padding: 20px 4%">
        <div class="projectHeader">
          <Breadcrumb
            style="float: left; line-height: 2rem; font-size: 1.25rem"
          >
            <a
              style="font-weight: 700"
              :href="'/#/projectDetail?id=' + projectId"
              >{{ projectName }}</a
            >
          </Breadcrumb>
        </div>
        <div style="width: 100%; float: left; margin-top: 35px">
          <Avatar v-if="faceUrl === ''" icon="ios-person" size="100" />
          <Avatar v-if="faceUrl !== ''" :src="faceUrl" size="100" />
        </div>
        <!--<div style="width: 70%;float: left;text-align:left; margin-left: 12%;margin-top: 35px">
                    You can check and operate the users' requests for this project in this page. <a style="color:#e9ab01"> Learn more.</a>
                </div>-->
        <div
          style="
            width: 90%;
            float: left;
            padding: 5% 20px;
            margin-left: 5%;
            text-align: left;
            margin-top: 20px;
          "
        >
          <Icon type="md-information-circle" size="20" color="green" />
          <p style="display: inline">
            Please approve reasonable applications. You have
            {{ reject_time }} chances to deny. You can report malicious
            applications.
          </p>
        </div>
        <Card
          style="
            width: 80%;
            float: left;
            padding: 20px 10%;
            margin-left: 10%;
            margin-top: 30px;
          "
        >
          <div style="text-align: left">
            <p style="display: inline; font-weight: 500; font-size: 1.025rem">
              Identity:
            </p>
            <p style="display: inline">{{ identity }}</p>
          </div>
          <div style="text-align: left">
            <p style="display: inline; font-weight: 500; font-size: 1.025rem">
              Your credit:
            </p>
            <p style="display: inline">{{ creditPoint }}</p>
          </div>
          <div style="text-align: left">
            <p style="display: inline; font-weight: 500; font-size: 1.025rem">
              Share:
            </p>
            <p style="display: inline">{{ shares }}</p>
          </div>
        </Card>
      </div>

      <div style="width: 70%; float: left">
        <div style="width: 100%; float: left">
          <div
            style="
              padding-top: 50px;
              width: 80%;
              margin-left: 3%;
              float: left;
              text-align: left;
            "
          >
            <div style="width: 100%; float: left">
              <div style="font-weight: 500; font-size: 1.1rem; display: inline">
                Project:
              </div>
              <span class="CWords" style="display: inline">{{
                projectName
              }}</span>
            </div>
            <div style="width: 96%; float: left; margin-top: 10px">
              <div
                style="
                  font-weight: 500;
                  font-size: 1.1rem;
                  margin-top: 20px;
                  display: inline;
                "
              >
                Abstract:
              </div>
              <span class="CWords" style="display: inline">{{ abstract }}</span>
            </div>
          </div>
        </div>
        <div style="width: 100%; float: left">
          <Menu mode="horizontal" active-name="1" @on-select="menuSelect">
            <MenuItem name="1">
              <Icon type="ios-stats" />
              Join request
            </MenuItem>
            <MenuItem name="3">
              <Icon type="ios-people" />
              Information request
            </MenuItem>
            <MenuItem name="4">
              <!-- <MenuItem name="4" v-if="identity==='owner'"> -->
              <Icon type="ios-copy" />
              Grant Segment
            </MenuItem>
            <MenuItem name="5">
              <Icon type="ios-eye" />
              Check Grant Segment
            </MenuItem>
          </Menu>
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "checkJoin",
  data() {
    return {
      projectId: "",
      projectName: "",
      userName: "",
      userId: "",
      creditPoint: 0,
      shares: 0,
      identity: "",
      reject_time: 0,
      abstract: "",
      faceUrl: "",
      modalReport: false,
    };
  },
  methods: {
    initial_fk() {
      var Tself = this;
      this.projectId = this.$route.query.id;
      this.userId = this.getCookie("user_id");
      this.$axios({
        headers: {
          "Content-Type": "application/text",
        },
        method: "post",
        url: "/project/findById",
        data: Tself.projectId,
      })
        .then(function (response) {
          var data = response.data.data.items;
          Tself.projectName = data.name;
          Tself.abstract = data.abstractInfo;
        })
        .catch((error) => {
          console.log(error);
          Tself.$Message.error(error.message);
        });

      this.$axios
        .post("/user/findById", Tself.getCookie("user_id"), {
          headers: {
            "Content-Type": "text/xml",
          },
        })
        .then((response) => {
          let uf = response.data.data.items;
          Tself.userName = uf.username;
          Tself.name = uf.username;
          Tself.creditPoint = uf.credit;
          Tself.faceUrl = uf.faceUrl;
        })
        .catch((error) => {
          console.log(error);
          this.$Message.error(error.message);
        });

      var dv0 = {
        projectId: this.projectId,
        userId: this.userId,
      };
      this.$axios
        .post("/contribution/findUniqueContribution", dv0, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          var vo = response.data.data.items;
          Tself.identity = vo.identity;
          Tself.shares = vo.shares;
        })
        .catch((error) => {
          console.log(error);
        });
      this.reject_time = 3;
    },
    menuSelect(name) {
      console.log("name: " + name);
      switch (name) {
        case "1":
          this.$router.push("/checkJoinList?id=" + this.projectId);
          break;
        case "3":
          this.$router.push("/checkFurtherList?id=" + this.projectId);
          break;
        case "4":
          location.href = "/#/grantSegment?id=" + this.projectId;
          break;
        case "5":
          location.href = "/#/grantSegmentList?id=" + this.projectId;
          break;
      }
    },
    getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i].trim();
        if (c.indexOf(name) !== -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
  },
  mounted() {
    this.initial_fk();
  },
};
</script>

<style scoped>
.projectHeader {
  margin: 20px 0;
  width: 100%;
  height: 2rem;
  padding: 0 3.125rem;
  float: left;
}
</style>
